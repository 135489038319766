<template>
  <div class="article">
    <page-nav title="资讯详情" />
    <div class="content" v-if="article">
      <h2>{{article.title}}</h2>
      <div class="subTitle">
        {{article.createTime}}
        <a class="link" :href="article.url" rel="noreferrer" target="_blank">来源：人民网</a>
      </div>
      <p class="cnt">{{article.content}}</p>
    </div>
    <div class="page-footer">
      <div class="footer-logo"><img src="../assets/headerLogo.png" /></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';
import Fetch from '@/common/fetch';
import { GetArticleDetail } from '@/common/interface';
import PageNav from '../components/PageNav.vue';

Vue.use(Toast);

export default {
  name: 'Article',
  components: { PageNav },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      article: null,
    };
  },
  methods: {
    fetchData() {
      const { id } = this.$route.query;
      const params = {
        id,
      };
      Fetch.post(GetArticleDetail, params).then(res => {
        this.article = res;
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.article {
  .content {
    padding: 0 16px;
    h2 {
      font-size: 17px;
      text-align: center;
      margin-top: 10px;
    }
    .subTitle {
      font-size: 13px;
      color: #999;
      text-align: center;
      margin: 8px 0;
      a {
        color: #666;
      }
    }
    .cnt {
      margin: 20px 0;
      white-space: pre-line;
      font-size: 14px;
      font-family: '微软雅黑';
    }
  }
}
</style>